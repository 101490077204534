<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="440px"
      min-width="300px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
          color="red"
          :content="calcFilters()"
          :value="calcFilters()"
          overlap
      >
        <v-btn
            class="mt-1"
            color="primary"
            v-bind="attrs"
            v-on="on"
            small
            text
        >
          <v-icon size="18">fas fa-filter</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-row
        justify="center"
        class="pt-3 mt-0 mb-0"
    >
      <v-col
          v-for="(param, index) in filterParams"
          :key="index"
          cols="12"
          xs="12"
          class="pl-7 pr-7"
      >
        <div v-if="param.type === 'date-range'">
          <v-menu
              ref="menu"
              v-model="param.menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="param.value"
                  :label="`system.${param.name[0]}, system.${param.name[1]}`"
                  append-icon="fas fa-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="param.value"
                color="primary"
                range
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="grey"
                  @click="param.value = []; param.menu = false"
              >
                {{ $t('system.clear') }}
              </v-btn>
              <v-btn
                  dark
                  class="pl-3 pr-3"
                  color="primary"
                  @click="param.menu = false"
              >
                {{ $t('system.save') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div v-else-if="param.type === 'autocomplete'">
          <v-autocomplete
              v-model="param.value"
              :items="param.items"
              :label="`system.${param.name}`"
              item-text="name"
              item-value="id"
              auto-select-first
              chips
              deletable-chips
              multiple
              dense
              outlined
              flat
              small-chips
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
          cols="12"
          xs="12"
          class="pl-7 pr-7 pb-2 text-right"
      >
        <v-btn
            @click.stop="clearSearchForm"
            class="mb-2 mr-2"
            color="primary"
            dark
            text
        >
          {{ $t('system.clear') }}
        </v-btn>
        <v-btn
            @click.stop="getEntries"
            class="mb-2"
            color="primary"
            dark
        >
          <v-icon
              size="18"
              class="mr-2"
          >fas fa-search
          </v-icon>
          {{ $t('system.search') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-menu>
</template>

<script>
export default {
  name: 'SimpleStaticFilters',

  data: () => ({
    menu: false,
    calendarMenu: [],
  }),

  props: {
    filterParams: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    calcFilters() {
      let count = 0;
      this.filterParams.forEach((param) => {
        if (Array.isArray(param.value)) {
          param.value.forEach((element) => {
            if (element) {
              count++;
            }
          });
        } else if (param.value) {
          count++;
        }
      });
      return count;
    },

    clearSearchForm() {
      this.filterParams.forEach((param) => {
        if (Array.isArray(param.value)) {
          param.value = [];
        } else {
          param.value = null;
        }
      });
      this.$emit('setFilters', this.filterParams);
    },

    getEntries() {
      this.$emit('setFilters', this.filterParams);
    },
  },
};
</script>
