<template>
  <div class="view-container">
    <div
        class="view"
    >
      <div class="view-body">
        <v-skeleton-loader
            class="mx-auto"
            type="table"
            :loading="loading && !formLoaded"
        >
          <v-data-table
              :footer-props="dataTableOptions.footerProps"
              :options.sync="dataTableOptions"
              :server-items-length="dataTableOptions.totalItems"
              :headers="headers"
              :items="data"
              :loading="loading"
              class="elevation-0"
              :single-expand="false"
              :expanded.sync="expanded"
              item-key="group_name"
              show-expand
              @item-expanded="expand"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('system.contractor_groups_plans') }}
                </v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-spacer />
                <SimpleStaticFilters
                    :filter-params="filterParams"
                    @setFilters="setFilters"
                />
              </v-toolbar>
            </template>
            <template v-slot:item.group_id="{ item }">
              #{{ item.group_id }}
            </template>
            <template v-slot:expanded-item="{ item }">
              <td
                  v-if="!componentKey"
              ></td>
              <td
                  v-if="componentKey"
                  style="position: relative;"
              >
                <v-btn
                    v-if="accountsByGroup[`group_${item.group_id}`].loading"
                    :loading="accountsByGroup[`group_${item.group_id}`].loading"
                    :disabled="accountsByGroup[`group_${item.group_id}`].loading"
                    text
                    style="position: absolute; left: 0; top: 7px"
                />
              </td>
              <td class="pt-3 pb-3">
                <div class="mb-2"><strong>{{ $t('system.account_name') }}</strong></div>
                <div
                    v-for="(element, index) in accountsByGroup[`group_${item.group_id}`].data"
                    :key="`account_name_${index}`"
                >
                  {{ element.account_name ? element.account_name : $t('system.no_data') }}
                </div>
                <div>
                  <v-btn
                      v-if="accountsByGroup[`group_${item.group_id}`].total > accountsByGroup[`group_${item.group_id}`].per_page * accountsByGroup[`group_${item.group_id}`].current_page"
                      @click="getAccountsByGroup(item, true)"
                      :loading="accountsByGroup[`group_${item.group_id}`].loading"
                      :disabled="accountsByGroup[`group_${item.group_id}`].loading"
                      small
                      color="secondary"
                  >
                    {{ $t('system.load_more') }} {{ accountsByGroup[`group_${item.group_id}`].loading }}
                  </v-btn>
                </div>
              </td>
              <td class="pt-3 pb-3">
                <div class="mb-2"><strong>{{ $t('system.target_expected') }}</strong></div>
                <div
                    v-for="(element, index) in accountsByGroup[`group_${item.group_id}`].data"
                    :key="`target_expected_${index}`"
                >
                  {{ element.target_expected ? element.target_expected : $t('system.no_data') }}
                </div>
              </td>
              <td class="pt-3 pb-3">
                <div class="mb-2"><strong>{{ $t('system.taget_achieved') }}</strong></div>
                <div
                    v-for="(element, index) in accountsByGroup[`group_${item.group_id}`].data"
                    :key="`taget_achieved_${index}`"
                >
                  {{ element.taget_achieved ? element.taget_achieved : $t('system.no_data') }}
                </div>
              </td>
              <td class="pt-3 pb-3">
                <div class="mb-2"><strong>{{ $t('system.last_month_sale') }}</strong></div>
                <div
                    v-for="(element, index) in accountsByGroup[`group_${item.group_id}`].data"
                    :key="`last_month_sale_${index}`"
                >
                  {{ element.last_month_sale ? element.last_month_sale : $t('system.no_data') }}
                </div>
              </td>
              <td class="pt-3 pb-3">
                <div class="mb-2"><strong>{{ $t('system.last_transaction_status') }}</strong></div>
                <div
                    v-for="(element, index) in accountsByGroup[`group_${item.group_id}`].data"
                    :key="`last_transaction_status_${index}`"
                >
                  {{ element.last_transaction_status ? element.last_transaction_status : $t('system.no_data') }}
                </div>
              </td>
              <td class="pt-3 pb-3">
                <div class="mb-2"><strong>{{ $t('system.is_new') }}</strong></div>
                <div
                    v-for="(element, index) in accountsByGroup[`group_${item.group_id}`].data"
                    :key="`is_new_${index}`"
                >
                  <v-chip
                      :color="element.is_new ? 'success' : 'secondary'"
                      small
                  >
                    {{ element.is_new ? $t('system.yes') : $t('system.no') }}
                  </v-chip>
                </div>
              </td>
              <td class="pt-3 pb-3">
                <div class="mb-2"><strong>{{ $t('system.visit_status') }}</strong></div>
                <div
                    v-for="(element, index) in accountsByGroup[`group_${item.group_id}`].data"
                    :key="`visit_status_${index}`"
                >
                  <v-icon :color="element.visit_status ? 'green' : 'red'">fas fa-car</v-icon>
                </div>
              </td>
              <td class="pt-3 pb-3">
                <div class="mb-2"><strong>{{ $t('system.account_priority_name') }}</strong></div>
                <div
                    v-for="(element, index) in accountsByGroup[`group_${item.group_id}`].data"
                    :key="`account_priority_name_${index}`"
                >
                  <v-chip
                      :color="element.account_priority_color || 'default'"
                      dark
                      small
                  >
                    {{ element.account_priority_name ? element.account_priority_name : $t('system.no_data') }}
                  </v-chip>
                </div>
              </td>
            </template>
            <template v-slot:no-data>
              {{ $t('system.no_data') }}
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SimpleStaticFilters from '@/components/Filters/SimpleStaticFilters';

export default {
  name: 'ContractorGroupsTarget',

  components: {
    SimpleStaticFilters,
  },

  data() {
    return {
      loading: true,
      formLoaded: false,
      expanded: [],
      accountsByGroup: {},
      componentKey: true,
      filterParams: [
        {
          type: 'autocomplete',
          name: 'account_group',
          items: [],
          value: null,
        },
      ],
      data: [],
      dataTableOptions: {
        page: 1,
        itemsPerPage: localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`) ? parseInt(JSON.parse((localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`))), 10) : 10,
        sortBy: ['group_name'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        totalItems: 5,
        footerProps: {
          itemsPerPageOptions: [10, 25, 50, 100],
          showFirstLastPage: true,
        },
      },
      headers: [
        {
          text: this.$t('system.group_name'),
          sortable: true,
          value: 'group_name',
        },
        {
          text: this.$t('system.group_id'),
          sortable: true,
          value: 'group_id',
        },
        {
          text: this.$t('system.target_expected'),
          sortable: true,
          value: 'target_expected',
        },
        {
          text: this.$t('system.target_achieved'),
          sortable: true,
          value: 'target_achieved',
        },
        {
          text: this.$t('system.pending_visits'),
          sortable: true,
          value: 'pending_visits',
        },
        {
          text: this.$t('system.sale_last_month'),
          sortable: true,
          value: 'sale_last_month',
        },
        {
          text: this.$t('system.pending_visits_nk'),
          sortable: true,
          value: 'pending_visits_nk',
        },
        {
          text: this.$t('system.achieved_visits_nk'),
          sortable: true,
          value: 'achieved_visits_nk',
        },
      ],
    };
  },

  watch: {
    dataTableOptions: {
      handler() {
        if (this.formLoaded) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },

  created() {
    this.fetchData();
    this.getAccountsGroups();
  },

  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const {data} = await axios.post(`/sk_visits/target/contractor_group`, this.prepareQuery());
        data.data.forEach((element) => {
          element.targetEdit = false;
          this.accountsByGroup[`group_${element.group_id}`] = {
            data: [],
          };
        });
        this.data = data.data;
        this.dataTableOptions.totalItems = data.total;
        this.formLoaded = true;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    prepareQuery() {
      return {
        account_groups_ids: this.filterParams.find((element) => element.name === 'account_group').value,
        per_page: this.dataTableOptions.itemsPerPage,
        page: this.dataTableOptions.page,
        order_by: this.dataTableOptions.sortBy[0] || 'id',
        direction: this.dataTableOptions.sortDesc[0] ? 'desc' : 'asc',
      };
    },

    async saveTarget(item) {
      if (!item.targetEdit) {
        item.targetEdit = true;
        return;
      }
      this.loading = true;
      try {
        await axios.put(`/sk_visits/target`, {
          account_id: item.account_id,
          target_value: item.target_expected,
        });
        item.targetEdit = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    setFilters(filterParams) {
      this.filterParams = filterParams;
      this.fetchData();
    },

    expand(element) {
      if (!this.accountsByGroup[`group_${element.item.group_id}`]) {
        this.accountsByGroup[`group_${element.item.group_id}`] = {};
      }
      this.getAccountsByGroup(element.item);
    },

    async getAccountsByGroup(element, addPage = false) {
      if (this.accountsByGroup[`group_${element.group_id}`].total <= this.accountsByGroup[`group_${element.group_id}`].per_page * this.accountsByGroup[`group_${element.group_id}`].current_page) {
        return;
      }
      if (addPage) {
        this.accountsByGroup[`group_${element.group_id}`].current_page++;
      }
      this.accountsByGroup[`group_${element.group_id}`].loading = true;
      try {
        const {data} = await axios.post('/sk_visits/target/contractor', this.prepareGetAccountsByGroup(element));
        this.accountsByGroup[`group_${element.group_id}`].data = this.accountsByGroup[`group_${element.group_id}`].data.concat(data.data);
        this.accountsByGroup[`group_${element.group_id}`].data
            .filter((thing, index, self) =>
                index === self
                    .findIndex((t) => (
                        t.account_id === thing.account_id && t.account_name === thing.account_name
                    )),
            );
        this.accountsByGroup[`group_${element.group_id}`].total = data.total;
        this.componentKey = false;
        setTimeout(() => {
          this.componentKey = true;
          this.$forceUpdate();
        });
      } catch (error) {
        console.log('error', error);
      } finally {
        this.accountsByGroup[`group_${element.group_id}`].loading = false;
      }
    },

    prepareGetAccountsByGroup(element) {
      if (!this.accountsByGroup[`group_${element.group_id}`].per_page || !this.accountsByGroup[`group_${element.group_id}`].current_page) {
        this.accountsByGroup[`group_${element.group_id}`].per_page = 10;
        this.accountsByGroup[`group_${element.group_id}`].current_page = 1;
        this.accountsByGroup[`group_${element.group_id}`].data = [];
      } else {
        this.accountsByGroup[`group_${element.group_id}`].current_page = this.accountsByGroup[`group_${element.group_id}`].current_page++;
      }
      return {
        account_groups_ids: [element.group_id],
        order: 'crm_accounts.id',
        direction: 'asc',
        per_page: this.accountsByGroup[`group_${element.group_id}`].per_page,
        page: this.accountsByGroup[`group_${element.group_id}`].current_page,
      };
    },

    async getAccountsGroups() {
      try {
        const {data} = await axios.get('/sk_visits/groups');
        this.filterParams.forEach((element) => {
          if (element.name === 'account_group') {
            element.items = data;
          }
        });
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>

<style>

.v-data-table__expanded__content {
  vertical-align: baseline;
}

.v-data-table__expanded__content div:not(.mb-2) {
  height: 40px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

</style>
